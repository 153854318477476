import jump from 'jump.js';

const updateNavbarAndTitle = slide => {
  if (slide.hasClass('ut-navbar-invert')) {
    if (
      !$('.ut-bar-white').hasClass('show') &&
      $('.uk-modal-page').length === 0
    ) {
      $('.uk-navbar').addClass('ut-invert-large ut-invert');
    }
    $('.ut-header').addClass('ut-invert');
  } else {
    $('.ut-header').removeClass('ut-invert');
    $('.uk-navbar').removeClass('ut-invert-large ut-invert');
  }
};

$(document).ready(() => {
  const videos = Array.from(document.querySelectorAll('.ut-header-video'));

  videos.forEach(video => {
    if (window.innerWidth < 768) {
      video.removeAttribute('data-autoplay');
      document.querySelector('.ut-header-block').classList.remove('hidden');
    }

    video.addEventListener('playing', () => {
      video.classList.remove('hidden');
      document.querySelector('.ut-header-block').classList.remove('hidden');
    });
  });

  setTimeout(() => {
    updateNavbarAndTitle($('.uk-slideshow .uk-active'));
  }, 1);

  $('.ut-header [data-uk-slideshow]').on(
    'beforeshow.uk.slideshow',
    (e, slide) => {
      updateNavbarAndTitle($(slide));
    }
  );

  $('.ut-header').on('click', function callback() {
    if ($('#fullpage').length === 1) {
      fullpage_api.moveSectionDown();
    } else {
      jump(this.nextElementSibling, {
        duration: 700,
      });
    }
  });
});
