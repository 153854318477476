const webcamModal = UIkit.modal('#webcamModal', { center: true });
const menuToggle = $('#menuToggle');
let scrollTo;
const webcamButton = $('#webcamButton');

webcamButton.on('click', () => {
  webcamModal.show();
});

webcamModal.on({
  'show.uk.modal': function show() {
    scrollTo = $('body').scrollTop();
    $('body').css('overflow', 'hidden');
    $('body').css('position', 'fixed');
    $('body').css('top', -scrollTo);
    menuToggle.addClass('open');
  },
  'hide.uk.modal': function hide() {
    $('body').css('overflow', '');
    $('body').css('position', '');
    $('body').css('top', '');
    $('body').animate({ scrollTop: scrollTo }, 0);
    menuToggle.removeClass('open');
  },
});
