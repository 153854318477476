/* eslint-disable import/first */
import IScroll from 'fullpage.js/vendors/scrolloverflow';
import fullpage from 'fullpage.js';
import 'jquery.cookie/jquery.cookie';
import 'iframe-resizer';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import 'uikit';
import 'uikit/dist/js/core/core';
import 'uikit/dist/js/core/utility';
import 'uikit/dist/js/core/dropdown';
import 'uikit/dist/js/core/touch';
import 'uikit/dist/js/core/modal';
import 'uikit/dist/js/core/grid';
import 'uikit/dist/js/components/grid';
import 'uikit/dist/js/components/accordion';
import 'uikit/dist/js/components/slideshow';

// import './modules/portrait';
import './modules/booking';
import './modules/header';
import './modules/grid';
import fadein from './modules/fadein';
import './modules/maps';
import './modules/webcam';
import './modules/callback';
import './modules/prices';

Sentry.init({
  dsn: 'https://1a6bda6bd7ac475880edaebd4fb57aec@sentry.urbantrout.io/10',
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 0.2,
});

const menuModal = UIkit.modal('#menu');
const bookingModal = UIkit.modal('#booking');
const webcamModal = UIkit.modal('#webcamModal');
const menuToggle = $('#menuToggle');
const menuFade = $('.ut-menu-fade');
let scrollTo;
const warningClose = $('.ut-close-warning');
const barWhite = $('.ut-bar-white');
const fullpageConfig = {
  licenseKey: 'BBB2EC60-F7884FB0-81B3672B-30DDE40D',
  normalScrollElements: '.scrollable',
  scrollOverflow: true,
  scrollOverflowOptions: {
    click: false,
  },
  scrollingSpeed: 1500,

  afterLoad(origin, destination) {
    setResponsiveFullpage();

    if (destination.item.classList.contains('ut-navbar-invert')) {
      $('.uk-navbar').addClass('ut-invert');
    } else {
      $('.uk-navbar').removeClass('ut-invert');
    }

    if (destination.item.classList.contains('ut-navbar-invert-left')) {
      $('.uk-navbar:not(.ut-sticky-flip)').addClass('ut-invert-large');
    } else {
      $('.uk-navbar:not(.ut-sticky-flip).uk-navbar').removeClass(
        'ut-invert-large'
      );
    }
    if (destination.item.classList.contains('ut-navbar-invert-right')) {
      $('.uk-navbar.ut-sticky-flip.uk-navbar').addClass('ut-invert-large');
    } else {
      $('.uk-navbar.ut-sticky-flip.uk-navbar').removeClass('ut-invert-large');
    }
  },
};

function setResponsiveFullpage() {
  if (window.innerWidth >= 960) {
    if (window.innerHeight < 670) {
      fullpage_api.setResponsive(true);
    } else {
      fullpage_api.setResponsive(false);
    }
  } else if (window.innerHeight < 440) {
    fullpage_api.setResponsive(true);
  } else {
    fullpage_api.setResponsive(false);
  }
}

$('iframe').iFrameResize({
  bodyBackground: '#fff',
  bodyPadding: '30px 0',
  initCallback: fadein,
});

setTimeout(() => {
  if (parseInt($.cookie('ut-c'), 10) !== 1) {
    $('#cookieDisclaimer').removeClass('close');
    $('.ut-warning-translate').css(
      'transform',
      `translateY(${$('#cookieDisclaimer').outerHeight(true)}px)`
    );
    $('#menu>div').css('padding-top', '120px');
  }
}, 1000);

if (document.getElementById('fullpage')) {
  /* eslint-disable-next-line */
  new fullpage('#fullpage', fullpageConfig);

  window.addEventListener('resize', () => {
    setResponsiveFullpage();
  });
}

$(window).on('scroll', () => {
  const scrollTop = $(window).scrollTop();
  if (scrollTop > 60) {
    barWhite.addClass('show');
    if (!$('#cookieDisclaimer').hasClass('close')) {
      barWhite.css(
        'transform',
        `translateY(${$('#cookieDisclaimer').outerHeight()}px)`
      );
    }
    $('.uk-navbar').removeClass('ut-invert-large ut-invert');
  } else {
    barWhite.removeClass('show');
    $('.ut-bar-white').css('transform', '');
    if ($('.uk-modal-page').length === 0) {
      if ($('[data-uk-slideshow] .uk-active').hasClass('ut-navbar-invert')) {
        $('.uk-navbar').addClass('ut-invert-large ut-invert');
      }
    }
  }
});

warningClose.on('click', () => {
  $.cookie('ut-c', 1, { expires: 365, path: '/' });
  $('#cookieDisclaimer').addClass('close');
  $('.ut-warning-translate').css('transform', '');
  $('#menu>div').css('padding-top', '');
  // barWhite.css('transform', '');
});

menuModal.on({
  'show.uk.modal': function showModal() {
    scrollTo = $('body').scrollTop();
    $('body').css('overflow', 'hidden');
    $('body').css('position', 'fixed');
    $('body').css('top', -scrollTo);
    menuToggle.addClass('open');
    menuFade.hide();
    $('.uk-navbar').removeClass('ut-invert-large ut-invert');
    ga('send', 'event', 'Navigation', 'show', document.location.href);
    if ($('#fullpage').length > 0) {
      fullpage_api.setAllowScrolling(false);
      fullpage_api.setKeyboardScrolling(false);
    }
  },
  'hide.uk.modal': function hideModal() {
    const $activePage = $('.fp-section.active');
    $('body').css('overflow', '');
    $('body').css('position', '');
    $('body').css('top', '');
    $('body').animate({ scrollTop: scrollTo }, 0);
    menuToggle.removeClass('open');
    menuFade.fadeIn('fast');
    if ($('[data-uk-slideshow] .uk-active').hasClass('ut-navbar-invert')) {
      $('.uk-navbar').addClass('ut-invert-large ut-invert');
    }
    if ($activePage.hasClass('ut-navbar-invert')) {
      $('.uk-navbar').addClass('ut-invert');
    }
    if ($activePage.hasClass('ut-navbar-invert-left')) {
      $('.uk-navbar:not(.ut-sticky-flip)').addClass('ut-invert-large');
    }
    if ($activePage.hasClass('ut-navbar-invert-right')) {
      $('.uk-navbar.ut-sticky-flip.uk-navbar').addClass('ut-invert-large');
    }
    ga('send', 'event', 'Navigation', 'hide', document.location.href);
    if ($('#fullpage').length > 0) {
      fullpage_api.setAllowScrolling(true);
      fullpage_api.setKeyboardScrolling(true);
    }
  },
});

menuToggle.on('click', () => {
  if (
    menuModal.isActive() ||
    bookingModal.isActive() ||
    webcamModal.isActive()
  ) {
    if (menuModal.isActive()) {
      menuModal.hide();
    }
    if (bookingModal.isActive()) {
      bookingModal.hide();
    }
    if (webcamModal.isActive()) {
      webcamModal.hide();
    }
  } else {
    menuModal.show();
  }
});

$('.ut-room-amount').on('focus', function focus() {
  if ($(this).val() === '0') {
    $(this).val('');
  }
});

$('.ut-room-amount').on('focusout', function focusout() {
  if ($(this).val() === '') {
    $(this).val('0');
  }
});

$('.ut-panel').on('click', function click() {
  const $currentPanel = $(this);
  $currentPanel.toggleClass('ut-panel-touch');
  if ($currentPanel.hasClass('ut-panel-touch')) {
    $currentPanel
      .parents('.uk-slideshow')
      .siblings('.uk-slidenav')
      .addClass('uk-hidden-small');
  } else {
    $currentPanel
      .parents('.uk-slideshow')
      .siblings('.uk-slidenav')
      .removeClass('uk-hidden-small');
  }
});

$('#packageSlider').on('show.uk.slideshow', function showSlideshow() {
  $('.ut-panel').removeClass('ut-panel-touch');
  $(this)
    .find('.uk-slidenav')
    .removeClass('uk-hidden-small');
});

$('#homeLink').on('click', () => {
  ga(
    'send',
    'event',
    'Navigation',
    'Homepage icon click',
    document.location.href
  );
});

$('#pageHomeLink').on('click', () => {
  ga(
    'send',
    'event',
    'Navigation',
    'Back to homepage click',
    document.location.href
  );
});
$('.ut-menu a').on('click', function menuItemClick() {
  ga('send', 'event', 'Navigation', 'Menu item click', $(this).attr('href'));
});
