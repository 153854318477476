/* global google */

$.each($('.ut-maps'), function callback() {
  const latLng = new google.maps.LatLng(47.2487719, 13.5593213);

  const mapOptions = {
    zoom: 14,
    center: latLng,
    disableDefaultUI: true,
    styles: [
      {
        featureType: 'all',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ececec',
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
          {
            gamma: 0.01,
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            saturation: -31,
          },
          {
            lightness: -33,
          },
          {
            weight: 2,
          },
          {
            gamma: 0.8,
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
          {
            lightness: 30,
          },
          {
            saturation: 30,
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            saturation: 20,
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            lightness: 20,
          },
          {
            saturation: -20,
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            lightness: 10,
          },
          {
            saturation: '-40',
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#d4d4d4',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [
          {
            saturation: 25,
          },
          {
            lightness: 25,
          },
          {
            color: '#7b2a2a',
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#b5b5b5',
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#674141',
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#952e2e',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#e45959',
          },
          {
            gamma: '4.99',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          {
            lightness: -20,
          },
        ],
      },
    ],
  };

  // Create the Google Map using our element and options defined above
  const map = new google.maps.Map(this, mapOptions);

  const manggei = {
    path:
      'M0,11c0,-6.076 4.925,-11 11,-11c6.075,0 11,4.924 11,11c0,3.992 -2.127,7.487 -5.309,9.415c0.334,-4.143 -1.576,-6.067 -1.81,-7.777c-0.256,-1.868 1.657,-2.953 1.942,-3.386c0.307,-0.466 0.202,-0.879 0.522,-1.342c0.241,-0.347 0.382,-0.973 0.536,-1.897c0.1,-0.602 -0.668,-1.017 -2.404,-1.281c-1.639,-0.249 -2.796,-0.256 -3.646,-0.101c-3.271,0.593 -4.341,1.555 -4.341,1.555c-0.576,0.361 -2.029,-0.099 -1.778,2.187c0.117,1.062 -0.625,4.443 -2.277,6.329c-0.577,0.658 -1.313,1.133 -2.104,1.547c-0.849,-1.56 -1.331,-3.348 -1.331,-5.249l0,0ZM13.008,21.818c-0.652,0.119 -1.322,0.182 -2.008,0.182c-3.173,0 -6.033,-1.344 -8.04,-3.493c0.537,-0.659 1.123,-1.417 1.671,-2.228c1.368,-2.029 2.217,-6.327 2.075,-7.622c-0.055,-0.505 -0.198,-0.994 -0.149,-1.121c0.038,-0.097 0.133,-0.159 0.133,-0.159c0,0 0.09,-0.041 0.165,-0.062c0.09,-0.025 0.181,-0.043 0.27,-0.062c0.125,-0.025 0.246,-0.05 0.358,-0.082c0.128,-0.036 0.293,-0.093 0.453,-0.193l0.105,-0.079c0,0 0.218,-0.18 0.755,-0.43c0.61,-0.283 1.631,-0.654 3.209,-0.94c0.733,-0.133 1.796,-0.138 3.395,0.105c0.847,0.129 1.212,0.299 1.488,0.446l0.1,0.062c-0.142,0.813 -0.291,1.146 -0.429,1.346c-0.124,0.179 -0.206,0.353 -0.265,0.516c-0.05,0.139 -0.084,0.276 -0.117,0.413c-0.054,0.227 -0.081,0.321 -0.15,0.427l-0.329,0.298c-0.219,0.194 -0.509,0.457 -0.785,0.777c-0.553,0.641 -1.226,1.567 -1.056,2.811c0.158,1.15 1.55,3.451 0.686,5.964c-0.427,1.241 -0.985,2.236 -1.535,3.124l0,0ZM12.763,6.603c0.283,-0.205 0.203,-0.905 -0.534,-0.761c-0.718,0.139 -0.879,0.674 -0.845,0.846c0.043,0.22 1.097,0.12 1.379,-0.085Z',
    fillColor: '#ff6400',
    fillOpacity: 1,
    scale: 1.5,
    strokeWeight: 0,
  };
  const circle = {
    path: 'M0,11a11,11 0 1,0 22,0a11,11 0 1,0 -22,0',
    fillColor: '#ffffff',
    fillOpacity: 1,
    scale: 1.5,
    strokeWeight: 0,
  };
  // Let's also add a marker while we're at it
  const markerOne = new google.maps.Marker({
    position: latLng,
    map,
    icon: circle,
  });
  const markerTwo = new google.maps.Marker({
    position: latLng,
    map,
    icon: manggei,
  });

  markerOne.setMap(map);
  markerTwo.setMap(map);
});
