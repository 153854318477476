import forEach from 'lodash/forEach';

const grid = UIkit.grid('.ut-grid', {
  animation: false,
  duration: 0,
});

if (grid !== undefined) {
  setTimeout(() => {
    grid.update();
  }, 500);

  $(window).on('resize', () => {
    setTimeout(() => {
      grid.update();
    }, 500);
  });

  grid.on('afterupdate.uk.grid', (e, children) => {
    setTimeout(() => {
      forEach(children, element => {
        if ($(element).hasClass('ut-grid-item')) {
          if ($(element).position().left > 0) {
            $(element).addClass('ut-grid-item-2nd');
          } else {
            $(element).removeClass('ut-grid-item-2nd');
          }
        }
      });
    }, 400);
  });
}
