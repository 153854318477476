import InView from 'inview';

const fadein = () => {
  Array.from(document.querySelectorAll('.ut-fadein > *')).forEach(element =>
    InView(element, isInView => {
      if (isInView) {
        $(element).addClass('ut-fadein-done');
      }
    })
  );
};

export default fadein;

setTimeout(() => {
  fadein();
}, 200);

if (document.location.hash) {
  // prevent page scroll
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 1);

  setTimeout(() => {
    $('html,body').animate(
      {
        scrollTop: $(document.location.hash).offset().top,
      },
      500,
      'easeInOutCubic'
    );
  }, 500);
}
