const modal = UIkit.modal('#global');
const forms = Array.from(document.querySelectorAll('[data-validate]'));

function showValidationErrors() {
  modal.show();

  setTimeout(() => {
    modal.hide();
  }, 2000);
}

forms.forEach(form => {
  form.addEventListener('submit', event => {
    event.preventDefault();
    const { target } = event;
    const requiredInputs = Array.from(target.querySelectorAll('[required]'));
    let errors = false;

    requiredInputs.forEach(input => {
      switch (input.type) {
        case 'text':
        case 'tel':
        case 'email':
        case 'number':
        case 'password':
          if (!input.value.length) {
            errors = true;
            showValidationErrors();
          }
          break;

        case 'radio':
        case 'checkbox':
          if (!input.checked) {
            errors = true;
            showValidationErrors();
          }
          break;

        default:
          break;
      }
    });

    // Submit form if there are no errors.
    if (!errors) {
      form.submit();
    }
  });
});
