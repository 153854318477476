import parse from 'date-fns/parse';
import 'webpack-jquery-ui/datepicker';
import forEach from 'lodash/forEach';

const menuModal = UIkit.modal('#menu');
const bookingModal = UIkit.modal('#booking');
const msgElement = $('.ut-message');
const menuToggle = $('#menuToggle');
const bookingToggle = $('.bookingToggle');
const bookingSticky = bookingToggle.parents('.ut-sticky');
const bookingForm = $('#bookingForm');
const barGray = $('.ut-bar-gray');
const addChild = $('#addChild');
const newChild = $('.ut-child')
  .first()
  .clone();
const arrivalDateInput = $('#arrivalDate');
const departureDateInput = $('#departureDate');
let date1;
let date2;
const lang = $('html').attr('lang');
let monthNames;
let dayNamesMin;

if (lang === 'de') {
  monthNames = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];
}

if (lang === 'de') {
  dayNamesMin = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
}

bookingToggle.on('click', ({ target }) => {
  const remarks = $(target).data('remarks');
  if (!bookingModal.isActive()) {
    if (menuModal.isActive()) {
      menuModal.hide();
      setTimeout(() => {
        bookingModal.show();
      }, 300);
    } else {
      bookingModal.show();
    }

    if (target.dataset.roomCategory) {
      const input = document.getElementById(target.dataset.roomCategory);
      input.value = 1;
    }
    $('.ut-room-amount').trigger('change');
    $('#remarks').val(remarks);

    ga('send', 'event', 'Booking Form', null, document.location.href);
  }
});

$('.ut-room-amount').on('change', () => {
  let text = '';
  const rooms = $('.ut-room-amount').filter((index, item) => item.value > 0);

  forEach(rooms, (item, index, list) => {
    const element = $(`label[for="${$(item).attr('id')}"]`);
    const html = element.html();
    const abbr = $(item).data('abbr');
    const amount = $(item).val();
    const multiplicator = ' x ';

    if (list.length > 2) {
      if (index === list.length - 1) {
        text = text + amount + multiplicator + abbr;
      } else {
        text = `${text + amount + multiplicator + abbr}, `;
      }
    } else if (index === list.length - 1) {
      text = text + amount + multiplicator + html;
    } else {
      text = `${text + amount + multiplicator + html}, `;
    }
  });
  $('#rooms').val(text);
});

function toTime(string) {
  const array = string.split('.');
  return parse(`${array[2]}-${array[1]}-${array[0]}`).getTime();
}

const dateRangeStatus = dateParam => {
  let start;
  let end;
  const date = parse(dateParam).getTime();

  if (date1 !== undefined) {
    start = toTime(date1);

    if (start === date) {
      return [1, 'ut-state-active'];
    }
  }

  if (date2 !== undefined) {
    end = toTime(date2);

    if (end === date) {
      return [1, 'ut-state-active'];
    }
  }
  if (date1 !== undefined && date2 !== undefined) {
    start = toTime(date1);
    end = toTime(date2);

    if (start <= date && end >= date) {
      return [1, 'ut-state-active'];
    }
  }

  return [1, ''];
};

arrivalDateInput.datepicker({
  firstDay: 1,
  nextText: '>',
  prevText: '<',
  selectOtherMonths: true,
  showOtherMonths: true,
  dateFormat: 'dd.mm.yy',
  minDate: new Date(),
  monthNames,
  dayNamesMin,
  beforeShowDay: dateRangeStatus,
  onClose(selectedDate) {
    arrivalDateInput.removeClass('ut-invalid');
    if (selectedDate) {
      date1 = selectedDate;

      departureDateInput.datepicker('option', 'minDate', selectedDate);

      if (date2 === undefined) {
        departureDateInput.focus();
      } else if (toTime(date1) >= toTime(date2)) {
        departureDateInput.datepicker('setDate', null);
        departureDateInput.focus();
      }
    }
  },
});

departureDateInput.datepicker({
  firstDay: 1,
  nextText: '>',
  prevText: '<',
  selectOtherMonths: true,
  showOtherMonths: true,
  dateFormat: 'dd.mm.yy',
  minDate: new Date(),
  monthNames,
  dayNamesMin,
  beforeShowDay: dateRangeStatus,
  onClose(selectedDate) {
    departureDateInput.removeClass('ut-invalid');
    if (selectedDate) {
      date2 = selectedDate;

      if (date1 === undefined) {
        arrivalDateInput.focus();
      } else if (toTime(date2) <= toTime(date1)) {
        arrivalDateInput.datepicker('setDate', null);
        arrivalDateInput.focus();
      }
    }
  },
});

$('#ui-datepicker-div').insertAfter('#arrivalDate');

bookingModal.on({
  'show.uk.modal': () => {
    $('body').css('overflow', 'hidden');
    menuToggle.addClass('open');
    bookingSticky.addClass('hidden');
    barGray.addClass('show');
    $('.ut-bar-white').css('transform', 'translateY(0)');
    $('.uk-navbar').removeClass('ut-invert-large ut-invert');
    if ($('#fullpage').length > 0) {
      fullpage_api.setAllowScrolling(false);
      fullpage_api.setKeyboardScrolling(false);
    }
  },
  'hide.uk.modal': () => {
    const $activePage = $('.fp-section.active');
    $('body').css('overflow', '');
    menuToggle.removeClass('open');
    bookingSticky.removeClass('hidden');
    barGray.removeClass('show');
    bookingForm.css('height', '');

    // unset dateranges
    arrivalDateInput.datepicker('option', 'minDate', null);
    arrivalDateInput.datepicker('option', 'maxDate', null);
    departureDateInput.datepicker('option', 'minDate', null);
    departureDateInput.datepicker('option', 'maxDate', null);

    $('#packageName').val('');
    $('#package').addClass('uk-hidden');

    $('.ut-bar-white').css('transform', '');
    if ($('[data-uk-slideshow] .uk-active').hasClass('ut-navbar-invert')) {
      $('.uk-navbar').addClass('ut-invert-large ut-invert');
    }
    if ($activePage.hasClass('ut-navbar-invert')) {
      $('.uk-navbar').addClass('ut-invert');
    }
    if ($activePage.hasClass('ut-navbar-invert-left')) {
      $('.uk-navbar:not(.ut-sticky-flip)').addClass('ut-invert-large');
    }
    if ($activePage.hasClass('ut-navbar-invert-right')) {
      $('.uk-navbar.ut-sticky-flip.uk-navbar').addClass('ut-invert-large');
    }
    ga('send', 'event', 'Booking Form', 'Closed', document.location.href);
    if ($('#fullpage').length > 0) {
      fullpage_api.setAllowScrolling(true);
      fullpage_api.setKeyboardScrolling(true);
    }
  },
});

bookingForm
  .on('keydown', e => {
    if (e.keyCode === 13) {
      if (!$(e.target).is('textarea')) {
        document.activeElement.blur();
        return false;
      }
    }
  })
  .on('submit', e => {
    e.preventDefault();
    $.ajax({
      url: `${e.target.action}&type=1337`,
      method: 'POST',
      dataType: 'json',
      data: $(e.target).serialize(),
    }).done(msg => {
      if (msg.status === 200) {
        bookingForm.fadeOut();
        msgElement.html(msg.message);
        msgElement.fadeIn();
        ga(
          'send',
          'event',
          'Booking Form',
          'submitted',
          document.location.href
        );
      } else {
        bookingForm.fadeOut();
        msgElement.html(msg.message);
        msgElement.fadeIn();
        setTimeout(() => {
          msgElement.fadeOut();
          bookingForm.fadeIn();
          msgElement.html(null);
        }, 2000);
      }
    });
  });

addChild.on('click', ({ target }) => {
  const clonedChild = newChild.clone();
  const childNumber = clonedChild.find('.ut-child-number');
  const childCount = $('.ut-child').length;
  const text = $(target).html();
  const alternativeText = $(target).attr('data-alternative-text');

  if (childCount === 1) {
    $(target).html(alternativeText);
    $(target).attr('data-alternative-text', text);
  }

  childNumber.html(`${childCount}.`);
  clonedChild.appendTo('#children');
  setTimeout(() => {
    clonedChild.removeClass('ut-hidden');
  }, 0);
  clonedChild.find('.ut-child-age').focus();
});

$('#children').on('click', '.ut-remove-child', ({ target }) => {
  let childCount;
  const text = addChild.html();
  const alternativeText = addChild.attr('data-alternative-text');
  const element = $(target);

  element.parents('.ut-child').addClass('ut-hidden');
  setTimeout(() => {
    element.parents('.ut-child').remove();
    $('#children')
      .find('.ut-child')
      .each((index, item) => {
        $(item)
          .find('.ut-child-number')
          .html(`${index}.`);
      });
    childCount = $('.ut-child').length - 1;

    if (childCount === 0) {
      addChild.html(alternativeText);
      addChild.attr('data-alternative-text', text);
    }
  }, 200);
});
