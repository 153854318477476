import parse from 'date-fns/parse';

const now = parse(new Date()).getTime();

$('.ut-season').on('click', function click() {
  const season = $(this).data('ut-season');

  $('.ut-season').removeClass('ut-season-active');
  $(this).addClass('ut-season-active');

  $.each($('.ut-room-price'), function callback() {
    if ($(this).data('ut-season') === season) {
      $(this).fadeIn('fast');
    } else {
      $(this).hide();
    }
  });
});

$.each($('.ut-season'), function callback() {
  const seasonStart = $(this).data('ut-season-start');
  const seasonEnd = $(this).data('ut-season-end');

  if (now >= seasonStart && now <= seasonEnd) {
    $(this).trigger('click');
  }
});
